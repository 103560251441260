<script setup lang="ts">
import {ref} from "vue";
import CloverFullLogo from "@/components/ui/images/CloverFullLogo.vue";
import WavesBg from "@/components/ui/images/WavesBg.vue";
import Alert from "@/components/ui/alerts/Alert.vue";
import Button from "@/components/ui/ButtonLinks/Button.vue";
import FormInput from "@/components/ui/forms/Input.vue";
import LoginPage from "./LoginPage.vue";
import {useBuyNow} from "./hooks";
import {getCsrfToken} from "@/api/utils.ts";


const buyNow = useBuyNow();
const props = withDefaults(defineProps<{ errors: string }>(), {
    errors: "[]",

});
const serverErrors: { [key: string]: string[] } = JSON.parse(props.errors);
const isSubmitting = ref(false);
const setSubmitting = () => isSubmitting.value = true;
const registerData = ref({
    create_first_name: "",
    create_last_name: "",
    create_email: "",
    create_email_confirmation: "",
    create_notifications: true,
    create_password: "",
    create_trial: !buyNow.value,
})

const getQsParams = () => window.location.search
const survey = ref(false);
const showLogin = ref(false);
</script>
<template>
    <LoginPage v-if="showLogin" :isRegisterPage="true"/>
    <div
        class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 z-10 relative pb-24"
        v-if="!survey && !showLogin">
        <div
            class="flex flex-col md:flex-row items-start justify-center md:space-x-12 pt-8 md:pt-16">
            <div class="space-y-6 mt-4 md:mt-0 w-full md:w-1/2">
                <a href="https://cloverlearning.com">
                    <CloverFullLogo/>
                    <span class="sr-only">Clover Learning Home Page</span>
                </a>
                <div
                    class="bg-white rounded-md shadow p-8 md:p-12 mb-12 space-y-2"
                >
                    <div class="text-center" v-if="!buyNow">
                        <h2
                            class="text-2xl md:text-4xl font-bold tracking-tight"
                        >
                            Try it free for 3 days!
                        </h2>
                        <p class="mt-2">
                            No credit card required. All the features of our
                            Student plan.
                        </p>
                    </div>
                    <div class="text-center" v-if="buyNow">
                        <h2
                            class="text-2xl md:text-4xl font-bold tracking-tight"
                        >
                            Create an Account
                        </h2>
                    </div>
                    <Alert
                        type="error"
                        title="Error creating account."
                        v-if="Object.keys(serverErrors).length"
                    >
                        <!--                        <template #message>-->
                        <!--                            <ul>-->
                        <!--                                <li v-for="(value, key) in serverErrors" :key="key">-->
                        <!--                                    {{ value[0] }}-->
                        <!--                                </li>-->
                        <!--                            </ul>-->
                        <!--                        </template>-->
                    </Alert>
                    <div class="flex flex-col space-y-8 mt-12">
                        <form :action="`/register${getQsParams()}`" method="POST" @submit="setSubmitting">
                            <input type="hidden" name="_token" :value="getCsrfToken()">
                            <div class="space-y-6">
                                <div class="grid md:grid-cols-2 gap-6">
                                    <FormInput
                                        type="text"
                                        id="create_first_name"
                                        name="create_first_name"
                                        label="First name"
                                        v-model="registerData.create_first_name"
                                        :error="serverErrors?.create_first_name"
                                    />
                                    <FormInput
                                        type="text"
                                        id="create_last_name"
                                        name="create_last_name"
                                        label="Last name"
                                        v-model="registerData.create_last_name"
                                        :error="serverErrors?.create_last_name"
                                    />
                                </div>
                                <div>
                                    <FormInput
                                        type="text"
                                        id="create_email"
                                        name="create_email"
                                        label="Email"
                                        v-model="registerData.create_email"
                                        :error="serverErrors?.create_email"
                                    />
                                </div>
                                <div>
                                    <FormInput
                                        type="text"
                                        id="create_email_confirmation"
                                        name="create_email_confirmation"
                                        label="Confirm Email"
                                        v-model="
                                            registerData.create_email_confirmation
                                        "
                                        :error="
                                            serverErrors?.create_email_confirmation
                                        "
                                    />
                                </div>
                                <div>
                                    <FormInput
                                        id="create_password"
                                        name="create_password"
                                        label="Password"
                                        type="password"
                                        v-model="registerData.create_password"
                                        :error="serverErrors?.create_password"
                                    />
                                </div>
                                <div>
                                    <label
                                        class="inline-flex items-center gap-2"
                                    >
                                        <input
                                            type="checkbox"
                                            name="create_notifications"
                                            checked
                                            label="Get product and discount notifications"
                                            v-model="
                                                registerData.create_notifications
                                            "
                                        />
                                        <div class="leading-9">
                                            Get product and discount
                                            notifications
                                        </div>
                                    </label>
                                </div>
                                <div class="text-sm text-center space-y-6">
                                    <div>
                                        By creating an account, you agree to our
                                        <a
                                            class="cta"
                                            href="https://cloverlearning.com/terms-of-use"
                                            target="_blank"
                                        >Terms of Use</a
                                        >
                                        and
                                        <a
                                            class="cta"
                                            href="https://cloverlearning.com/privacy-policy"
                                            target="_blank"
                                        >Privacy Policy</a
                                        >.
                                    </div>
                                </div>
                                <div class="text-center">
                                    <Button
                                        intent="primary"
                                        size="lg"
                                        type="submit"
                                        :isDisabled="isSubmitting"
                                    >
                                        Create Account
                                    </Button>
                                </div>
                                <div class="text-center text-sm">
                                    <p>
                                        <a class="cta" href="/redeem">
                                            Redeem access code </a
                                        >&nbsp;| Already have an account?
                                        <a
                                            href=""
                                            class="cta"
                                            @click.prevent="showLogin = true"
                                        >Sign In</a
                                        >
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-full fixed bottom-0 left-0 right-0 z-0">
        <WavesBg></WavesBg>
    </div>
</template>
