<template>
    <div
        class="relative flex items-start md:items-center justify-between bg-white md:bg-transparent md:border-b md:border-gray-100 last:border-b-0 rounded-md shadow md:rounded-none md:shadow-none w-full"
    >
        <div
            class="flex flex-col md:flex-row md:items-center md:pl-0 w-full md:w-auto"
        >
            <div class="md:pl-6 md:w-80">
                <h2
                    class="text-lg md:text-base font-medium leading-6 py-5 pl-5 md:py-0 md:pl-0 truncate"
                    :title="assignment.name"
                    v-text="assignment.name"
                />
            </div>
            <div
                class="flex items-start justify-start space-x-4 md:space-x-0 pb-6 md:py-6 pl-5 md:pl-0 md:pr-6 md:w-56"
            >
                <div class="flex md:hidden">
                    <SvgIcon
                        class="text-green-500 mt-1"
                        icon="exam"
                        size="sm"
                        weight="medium"
                    />
                </div>
                <div class="md:text-sm w-full">
                    <div class="md:hidden font-bold">Exam</div>
                    <a
                        v-if="
                            !assignment.blueprint.mock &&
                            !assignment.blueprint.quick
                        "
                        class="block truncate cta"
                        :href="
                            '/prep/exam-library?exam=' +
                            assignment.blueprint.uuid
                        "
                        v-text="assignment.blueprint.name"
                    />
                    <div
                        v-else
                        class="truncate"
                        v-text="assignment.blueprint.name"
                    />
                </div>
            </div>
            <div
                class="flex items-start md:text-sm space-x-4 md:space-x-0 pl-5 pb-6 md:pl-0 md:pb-0 md:w-48"
            >
                <div class="flex md:hidden">
                    <SvgIcon
                        class="text-green-500 mt-1"
                        icon="calendar"
                        size="sm"
                        weight="medium"
                    />
                </div>
                <div class="flex flex-col items-start justify-start">
                    <div>
                        <strong class="inline-block mr-4 md:mr-0 w-10">
                            Start
                        </strong>
                        <span
                            class="text-sm md:text-xs font-mono"
                            v-text="
                                $filters.shortDateTime(
                                    assignment.start_date,
                                    user.timezone
                                )
                            "
                        />
                    </div>
                    <div>
                        <strong class="inline-block mr-4 md:mr-0 w-10">
                            Due
                        </strong>
                        <span
                            class="text-sm md:text-xs font-mono"
                            v-text="
                                $filters.shortDateTime(
                                    assignment.end_date,
                                    user.timezone
                                )
                            "
                        />
                    </div>
                </div>
            </div>
            <div
                class="flex items-start justify-start space-x-4 md:space-x-0 pl-5 pb-6 md:pl-6 md:pb-0 md:w-60"
            >
                <div class="flex md:hidden">
                    <SvgIcon
                        class="text-green-500 mt-1"
                        icon="assignment"
                        size="sm"
                        weight="medium"
                    />
                </div>
                <div class="md:text-sm">
                    <div class="md:hidden font-bold">Assignee</div>
                    <a
                        class="cta"
                        :href="pulseAssignableLink(assignable)"
                        v-text="assignable.name"
                        target="_blank"
                    />
                </div>
            </div>
            <div
                class="flex items-start md:justify-center space-x-4 md:space-x-0 pl-5 pb-5 md:pl-0 md:pb-0 md:w-24"
            >
                <div class="flex md:hidden">
                    <SvgIcon
                        class="text-green-500 mt-1"
                        icon="checklist2"
                        size="sm"
                        weight="medium"
                    />
                </div>
                <div class="md:text-sm">
                    <div class="md:hidden font-bold">Attempts</div>
                    <div>{{ assignment.exams_count }} / {{ learnerCount }}</div>
                </div>
            </div>
        </div>
        <div class="flex items-center absolute md:relative top-0 right-0">
            <div
                class="flex items-center justify-center py-4 md:py-6 md:px-6 pl-0 space-x-6 w-14"
            >
                <ActionMenu>
                    <ActionMenuAction
                        type="link"
                        label="Reporting"
                        icon="pulse"
                        iconColor="text-pink-200"
                        :action="pulseLink"
                    />
                    <ActionMenuAction
                        v-if="assignment.deletable"
                        type="link"
                        label="Edit"
                        icon="edit"
                        iconColor="text-green-500"
                        :action="editLink"
                    />
                    <ActionMenuAction
                        v-if="assignment.deletable"
                        type="button"
                        label="Delete"
                        icon="remove"
                        iconColor="text-red-200"
                        :action="showDelete"
                    />
                </ActionMenu>
                <ConfirmDeleteModal
                    v-if="assignment.deletable"
                    :active="deleteModalVisible"
                    @close="deleteModalVisible = false"
                    @confirm="confirmDelete"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState } from "vuex";

export default {
    props: {
        assignment: {
            type: Object,
            default: () => {},
        },
    },
    data(): any {
        return {
            deleteModalVisible: false,
            formStatus: "ready",
        };
    },
    computed: {
        ...mapState(["user"]),
        assignable() {
            if (this.assignment.assignables.length > 0) {
                return this.assignment.assignables[0];
            }
            return {};
        },
        editLink() {
            return "/prep/assignments/edit/" + this.assignment.uuid;
        },
        learnerCount() {
            if (
                this.assignable.pivot.assignable_type == "App\\Cohort" ||
                this.assignable.pivot.assignable_type == "App\\Group"
            ) {
                return this.assignable.learners.length;
            }
            return 1;
        },
        pulseLink() {
            return this.pulseAssignmentLink(this.assignment, this.assignable);
        },
    },
    methods: {
        pulseAssignmentLink(assignment, assignable) {
          const startDate = new Date(this.assignment.start_date);
          const endDate = new Date(this.assignment.end_date);

          const period =
            startDate.getFullYear() +
            (startDate.getMonth() + 1).toString().padStart(2,'0') +
            startDate.getDate().toString().padStart(2,'0)') +
            "-" +
            endDate.getFullYear() +
            (endDate.getMonth() + 1).toString().padStart(2,'0') +
            endDate.getDate().toString().padStart(2,'0');

          return (
              "/pulse/explore/prep" +
              "?assignment=" +
              assignment.uuid +
              "&subject=" +
              assignable.uuid +
              "&period=" +
              period
          );
        },
        pulseAssignableLink(assignable) {
            return (
                "/pulse/explore/prep" +
                "?subject=" +
                assignable.uuid +
                "&period=1w"
            );
        },
        confirmDelete() {
            axios
                .delete("/api/prep/assignments/" + this.assignment.uuid)
                .then((response) => {
                    window.location.reload();
                });
        },
        showDelete() {
            this.deleteModalVisible = true;
        },
    },
};
</script>
