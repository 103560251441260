<template>
    <div class="space-y-4" v-if="!isLoading">
        <CategoryBreadcrumbs></CategoryBreadcrumbs>
        <h1
            class="pt-4 text-2xl font-semibold tracking-tight flex items-center gap-2"
        >
            <span>{{ bank }}</span>
            <div class="flex items-center gap-1">
                <Pill backgroundColor="green-50 border border-green-200"
                    >{{ catStats?.published ?? "0" }} Published</Pill
                >
                <Pill backgroundColor="yellow-50 border border-yellow-200"
                    >{{ catStats?.draft ?? "0" }} Draft</Pill
                >
                <Pill backgroundColor="red-50 border border-red-200"
                    >{{ catStats?.closed ?? "0" }} Closed</Pill
                >
            </div>
        </h1>
        <div class="mt-10 space-y-4">
            <ContentQuestionFilter v-model="filters"></ContentQuestionFilter>
            <PaginationManager
                :pagination-data="questionsPage"
                @next="
                    () => {
                        page++;
                    }
                "
                @prev="
                    () => {
                        page--;
                    }
                "
                v-if="questionsPage?.data"
            ></PaginationManager>
            <ContentQuestionsList
                :questions="questionsPage?.data"
                questionType="prep"
                v-if="questionsPage?.data"
            />
            <PaginationManager
                :pagination-data="questionsPage"
                @next="
                    () => {
                        page++;
                    }
                "
                @prev="
                    () => {
                        page--;
                    }
                "
                v-if="questionsPage?.data"
            ></PaginationManager>
        </div>
    </div>
    <div v-else>
        <h1>Loading...</h1>
    </div>
</template>

<script setup lang="tsx">
/**
 * Component to display a question bank category page
 */

import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useQuery } from "@tanstack/vue-query";
import { adminApi } from "@/api/admin";
import ContentQuestionsList from "@/components/Admin/Content/questions/ContentQuestionsList.vue";
import PaginationManager from "@/components/PaginationManager.vue";
import ContentQuestionFilter, {
    FilterOptions,
} from "../Content/ContentQuestionFilter.vue";
import { CategoryBreadcrumbs } from "./CategoryBreadcrumbs.tsx";

const filters = ref<FilterOptions>({
    access: "open",
    flagged: "all",
    keyword: "",
    status: "published",
});

const route = useRoute();
const bankId = computed(() => parseInt(route.params.id as string));
const categoryId = computed(() => parseInt(route.params.categoryId as string));

const page = ref(1);
const { data: questionsPage, isLoading } = useQuery({
    queryKey: [
        "bank",
        bankId,
        "category",
        categoryId,
        "questions",
        page,
        filters,
    ],
    queryFn: async () => {
        const response = await adminApi.questionBank.searchQuestions({
            deep: true,
            page: page.value,
            ...filters.value,
            bankId: bankId.value,
        });
        return response.data;
    },
});

const { data: catStats } = useQuery({
    queryKey: ["bank", bankId, "category", categoryId, "stats"],
    queryFn: async () => {
        const response = await adminApi.questionBank.categoryStats(
            bankId.value,
            categoryId.value
        );
        return response.data;
    },
});

const { data: bank } = useQuery({
    queryKey: ["bank", bankId],
    queryFn: async () => {
        const response = await adminApi.questionBank.getBank(bankId.value);
        return response?.name ?? "";
    },
});

watch(categoryId, () => {
    page.value = 1;
});
</script>
